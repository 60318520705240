'use strict';

$(
	function() {
		app.assignNumberControl();
		app.setSelectedLink();
		app.addRegisterCheckBoxes();
		app.attachSvgEvents();
		app.setBackUrls();
		app.addCoverSpan();
		app.hamburger.init({
			look: 1
		});
		app.topMenu.init();
		if(!app.isMobile()) {
			$( window ).scroll(
				function(){
					app.floatingHeader();
				}
			);
			app.floatingHeader();
		}
		app.initializeScrollButton();
		app.assignProductOver();
		if (app.extraMenu) {
			app.extraMenu.init({
				side: 'left',
				selector: '.extramenu-hamb'
			});
		}
		$('.menu > ul > li').addClass('schema-brd-color-over');
	}
);

var app = {
	_vars: {
		putAttributesToProdItemsDirectly: true,
		_customFonts: {
			FontAwesome: true
		},
		_guid: '457c56e0-565d-4ee7-999f-2ce98301773e'
	},
	_VERSION: 2,
	responsiveMode: true,
	indexLinkPos: 0,
	messages: {},
	productAvailableQuantityUrl: null,
	optionsWereInitialized: false,
	fixTop: 0,
	showSearch: function() {
		if ($('.li-search .ul-mega-1').hasClass('hidden')) {
			$('.li-search').addClass('hover');
			$('.li-search .ul-mega-1, .li-search .delimiter').removeClass('hidden');
		} else {
			$('.li-search').removeClass('hover');
			$('.li-search .ul-mega-1, .li-search .delimiter').addClass('hidden');
		}
	},
	runIndexCarusel: function() {
		app.runCarusel({
			innerHTML: '<div class="zoomer"><div class="inner"></div></div>'
		});
		$('.jcarousel-list li').each(
			function() {
				var href = $(this).find('.image a').attr('href');
				$(this).find('.zoomer').click(
					function() {
						document.location = href;
					}
				)
			}
		)
	},
	doBeforePriceSliderShown: function(trg) {
		var color = app.getClassColor('schema-color');
		$('.noUi-connect').css('background-color', color);
	}
};